<template>
  <div class="iframe-container"></div>
</template>
<script>
import { ZoomMtg } from "@zoomus/websdk";
import NestScheduleService from "@/services/NestScheduleService";
import ProgramService from "@/services/ProgramService";
import $ from "jquery";
import errorLog from "@/errorLog";

ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.9/lib", "/av");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
export default {
  name: "ZoomFrame",
  data() {
    return {
      meetingNumber: "",
      password: ""
    };
  },
  props: {
    eventId: String
  },
  computed: {
    user() {
      let user = this.$store.state.studentAuth.user;
      if (user.length == undefined) {
        user = {};
      } else {
        user = JSON.parse(user);
      }
      return user;
    }
  },
  created: function() {
    this.getMeetingDetails();
  },
  methods: {
    getMeetingDetails: function() {
      NestScheduleService.getMeetingDetails({ event_id: this.eventId })
        .then(result => {
          console.log(result.data);
          if (result.data.status == "success") {
            this.meetingNumber = result.data.account.meeting_id;
            this.password = result.data.account.password;
            this.startMeeting();
          } else {
            this.$toast.error(result.data.message);
            this.$router.push({
              name: "nestEvents",
              query: { from: "zoom-error" }
            });
          }
        })
        .catch(error => {
          this.examLoader = false;
          console.log(error);
          errorLog.log(error);
        });
    },
    startMeeting() {
      var API_KEY = "2641ZBZYTH6UgGAvd34p4g";
      // var API_SECRET = "JY74L27P7c3exhWQ7SkGcQqwNnJ7Sh28x7PR";
      const meetConfig = {
        apiKey: API_KEY,
        meetingNumber: this.meetingNumber,
        leaveUrl:
          process.env.BASE_URL +
          "dashboard/nest/events?from=event-success&schedule=" +
          this.eventId,
        userName: this.user.first_name,
        userEmail: this.user.email,
        passWord: this.password, // if required
        role: 0 // 1 for host; 0 for attendee
      };

      // var signature = ZoomMtg.generateSignature({
      //   meetingNumber: meetConfig.meetingNumber,
      //   apiKey: API_KEY,
      //   apiSecret: API_SECRET,
      //   role: meetConfig.role,
      //   success: function (res) {
      //     console.log(res.result);
      //     meetConfig.signature = res.result;
      //     meetConfig.apiKey = API_KEY;
      //   },
      // });
      this.generateSignature(
        meetConfig.meetingNumber,
        meetConfig.role,
        meetConfig
      );
      // this.getSignature(meetConfig, signature);
    },
    async generateSignature(meetingNumber, role, meetConfig) {
      var signature = await ProgramService.generateSignature({
        meeting_number: meetingNumber,
        role: role
      });
      this.joinMeeting(meetConfig, signature.data);
    },
    joinMeeting(meetConfig, signature) {
      ZoomMtg.init({
        leaveUrl: meetConfig.leaveUrl,
        isSupportAV: true,
        success: function() {
          ZoomMtg.join({
            signature: signature,
            apiKey: meetConfig.apiKey,
            meetingNumber: meetConfig.meetingNumber,
            userName: meetConfig.userName,
            passWord: meetConfig.passWord,
            error(res) {
              console.log(res);
            }
          });
        }
      });
    },
    changeJoinText: function() {
      $(".join-meeting").text("Joining Class..");
    }
  },
  mounted: function() {
    setInterval(this.changeJoinText, 1000);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
