<template>
  <div class="meeting">
    <div class="report-content">
      <div class="report-infobox">
        <div class="container">
          <EventZoomFrame :eventId="eventId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventZoomFrame from "./components/EventZoomFrame.vue";
export default {
  name: "Meeting",
  data: function() {
    return {
      meetingId: "",
      eventId: this.$route.params.id,
      isProcessing: true
    };
  },
  components: {
    EventZoomFrame
  }
};
</script>

<style>
.container-zoom {
  width: 70%;
  height: 50%;
}
</style>
